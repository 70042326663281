@import "mixins";

.container {
    width: 100%;
}

.map {
    @include fixed-aspect;
}

.searchBar {
    position: relative;
    width: 100%;
}

.searchBox {
    @include conversationBox;
    background-color: $input-background;
    padding: 1rem 2.5rem;
    width: 100%;
}

.searchIcon {
    left: .5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.returnIcon {
    position: absolute;
    right: .5rem;
    top: 50%;
    transform: translateY(-50%);
}

.predictionDisplay {
    
    .locationButton {
        @include conversationBox;
        background-color: $button-background;
        color: $text-negative;
        text-align: left;
    }
}
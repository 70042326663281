.container {
    position: relative;
}

.videoBar {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 0;
    padding: .5rem;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(255,255,255,.4);

    button {
        padding: .4rem;
    }
}

.typeSelector {
    border: 1px solid #000;
    border-radius: .5rem;
    display: flex;
    overflow: hidden;
}

.pictureButton {
    border-right: 1px solid #000;
    padding: .5rem;
    background-color: #fff;
}

.videoButton {
    border-right: 1px solid #000;
    padding: .5rem;
    background-color: #fff;
}

.audioButton {
    padding: .5rem;
    background-color: #fff;
}

.modeSelected {
    background-color: #89ACCC;
    color: #000000;
    border-color: #000000; 
}


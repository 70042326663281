@import "mixins";

.selectButton {
    @include conversationBox;
    background-color: $button-background;
    color: $text-negative;

    &.selected {
        background-color: $text-primary;
        color: $input-background;
    }
}


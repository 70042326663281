@import "mixins";

.header {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all .3s;
    font-size: 1.25rem;

    a, a:visited, a:active {
        color: inherit;
    }

    .left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 65%;
        transition: 
            opacity .3s 0s,
            transform .3s 0s;

    }
    .right {
        margin: .5rem 0 .5rem auto;
        display: flex;
    }

    &.minimized {
        background: transparent;

        .nav {
            align-items: flex-start;
        }

        .left {
            opacity: 0;
            transform: translateY(-50%);
        }

        .initials {
            display: none;
        }

        .menuButton {
 
            label {
                display: none;
            }
        }
    }
}

.initials {
    background-color: #000;
    color: #fff;
    border-radius: 2rem;
    height: 3rem;
    width: 3rem;
    margin-right: .5rem;
    font-size: 1rem;

    > span {
        display: block;
        text-align: center;
        line-height: 3rem;
    }
}

.nav {
    align-items: center;
    display: flex;
    @include section;
}

.logo {
    background-blend-mode: multiply;
    background-color: $theme-primary;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 76px;
    width: 330px;

    @media screen and (max-width: 600px) {
        height: 46px;
        width: 200px;
    }
}

.S-NM {
    background-image: url("https://dms-cf-06.dimu.org/image/022yiVGVmfQh");
}

.S-SOR {
    background-image: url("https://dms04.dimu.org/image/013AhtPrnYw1");
}

.S-BM {
    background-image: url("https://dms-cf-07.dimu.org/image/022yiVGVketP");
}

.S-JO {
    background-image: url("https://dms-cf-02.dimu.org/image/02348xDrF4bM");
}

.S-VNM {
    background-image: url("https://dms01.dimu.org/image/013AhtPrnYw2");
}

.S-PM {
    background-image: url("https://dms-cf-01.dimu.org/image/0331xTtRdZ8b");
}

.S-LAN {
    background-image: url("https://dms-cf-07.dimu.org/image/0136NRPrqJqd");
}

.S-SHMA {
    background-image: url("https://dms-cf-09.dimu.org/image/022yiVGVkzDY");
}

.S-AM {
    background-image: url("https://dms-cf-09.dimu.org/image/0231uU2sN26k");
}

.S-FVM {
    background-image: url("https://dms-cf-02.dimu.org/image/022yiVGVmKd5");
}

.ANM {
    background-image: url("https://dms-cf-07.dimu.org/image/013AjPqHhTSd");
}

.SVG {
    background-image: url("https://dms-cf-03.dimu.org/image/022yiVGVmKvn");
}

.VMU {
    background-image: url("https://dms-cf-09.dimu.org/image/0138WvCMmGiF");
}

.JÆ {
    background-image: url("https://dms-cf-09.dimu.org/image/032yiVGVmKd7");
}

.MND {
    background-image: url("https://dms-cf-06.dimu.org/image/032yiVGVmexC");
}

.RFM {
    background-image: url("https://dms-cf-09.dimu.org/image/02348wmXCoCi");
}

.NF {
    background-image: url("https://dms04.dimu.org/image/013Ajt36fMrD?");
}

.NFS {
    background-image: url("https://dms-cf-07.dimu.org/image/013AjPqHhTWx");
}

.VSM {
    background-image: url("https://dms-cf-02.dimu.org/image/032yiVGVmKvg");
}

.SFM {
    background-image: url("https://dms-cf-10.dimu.org/image/032yiVGVkyuu");
}

//Menu
.menu {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    color: #000;
    padding: 0 4rem;
    height: 100vh;
    font-size: 1.25rem;
    width: 36rem;
    max-width: 100%;
    display: none;

    &_visible {
        display: block;
        animation: slideIn .3s ease-in-out;
    }

    .close {
        position: absolute;
        right: 2rem;
        top: 2rem;
    }

    ul {
        margin-top: 7rem;
        list-style-type: none;
        padding: 0;

        li {
            width: 100%;
            box-sizing: border-box;
            padding: 1rem 2rem;
            border-bottom: 1px solid #000;

            &:last-child {
                border: none;
            }

            .logoutButton {
                background-color: $button-background;
                color: $text-negative;
                float: right;
                font-size: 1rem;
                padding: .5rem;
                margin-top: -.4rem;
            }
        }
    }

    select {
        @include input;
        margin-left: -.20rem;
        width: 100%;
        text-transform: capitalize;
    }

    a {
        text-decoration: none;
        color: inherit;
    }
}

.version {
    position: absolute;
    font-size: .75rem;
    bottom: 1rem;
    right: 1rem;
    opacity: .4;
}

@keyframes slideIn {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

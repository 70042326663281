@import "mixins";

.submit {
    @include conversationBox;
    background-color: $button-background;
    color: $text-negative;
    margin-left: auto;

    &:disabled {
        background-color: gray;
        border: gray;
        color: #aaa;
    }

    &:last-of-type {
        border-bottom-right-radius: 0;
    }
}

.accepted {
    @include conversationBox;
    background-color: #fff;
    color: #000;
    margin-left: auto;
    border-bottom-right-radius: 0;
}

.inputBox {
    @include inputBox;
}

.inputRow {
    @include inputRow;

    >h4 {
        margin: 0;
    }

    >select {
        font-size: 1rem;
        padding: .2rem;
        width: 100%;
    }
}
@import "mixins";

.submit {
    @include conversationBox;
    background-color: $button-background;
    color: $text-negative;
    margin-left: auto;

    &:disabled {
        background-color: gray;
        border: gray;
        color: #aaa;
    }

    &:last-of-type {
        border-bottom-right-radius: 0;
    }
}

.submitting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.loader {
    @include loader;
}
@import "mixins";

.memoryGridWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -.5rem;
}

.gridItem {
    flex: 1 1 0;
    min-width: 300px;
    margin: .5rem;
    padding: .5rem;
    background-color: #fff;
    opacity: .8;
    color: #000;
}

.imageWrapper {
    position: relative;
    background-color: cadetblue;
    width: 100%;

    h3 {
        font-size: 1.5rem;
        position: absolute;
        color: #fff;
        top: 1rem;
        left: 1rem;
        margin: 0;
        padding: 0;
    }
}

.mediaDisplay {
    @include fixed-aspect;
    overflow: hidden;

    img,video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        filter: brightness(40%)
    }
}

.infoRow {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    align-items: center;

    span {
        display: block;
        opacity: .6;
    }

    .label {
        opacity: 1;
    }

    .authorRow {
        display: flex;
        align-items: center;

        .icon {
            font-size: 2.5rem;
            margin-right: .3rem;
        }
    }    
}


.editRow {
    display: flex;
    justify-content: flex-end;
    padding: 1rem .5rem .5rem;

    a:not(hidden) {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        text-decoration: none;
        color: inherit;
    }

    label {
        text-decoration: underline;
    }
}

.moreButton {
    margin: 2rem auto 0;
    padding: .5rem;
    flex-direction: column-reverse;

    span {
        font-size: 3rem
    }
}

.loader {
    @include loader;
    border: 0.4rem solid #fff;
    border-top: .4rem solid #3498db;
    margin: 2rem auto;
}


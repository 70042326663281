@import "mixins";

.termsBackground {
    background-color: rgba(0, 0, 0, .8);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.termsHeader {
    background-color: $theme-primary;
    padding: 2rem;
    opacity: .6;

    .headerLabel {
        margin: 0 auto;
        text-align: center;

        h2 {
            margin: .4rem 0 0;
        }

        span {
            display: block;
            font-size: 3rem;
        }
    }

    > button {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

.terms {
    background-color: rgb(246, 243, 243);
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    max-width: 1000px;
    max-height: calc(100vh - 2rem);
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 12px 28px rgba(0, 0, 0, .3), 0 2px 4px rgba(0, 0, 0, .5);

    .termsContent {
        position: relative;
        overflow-y: scroll;
        max-height: calc(100% - 10rem);
        overscroll-behavior: contain;
        padding: 2rem 1.5rem;
    }
}

.submit {
    @include conversationBox;
    background-color: $button-background;
    color: $text-negative;
    margin-left: auto;
}

.accepted {
    @include conversationBox;
    background-color: #fff;
    color: #000;
    margin-left: auto;
    border-bottom-right-radius: 0;
}
.answerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 1rem 0 .5rem auto;
    width: 80%;
}

.answer {
    background: #fff;
    border-radius: 1rem 1rem 0px 1rem;
    max-width: 100%;
    overflow: hidden;

    p { 
        margin: 1rem;
        white-space: break-spaces;
        word-wrap: break-word;

        &:empty {
            display: none;
        }
    }
}

.answer + .answer {
    margin-top: .4rem;
}

.mediaContainer {
    position: relative;
    min-width: 12rem;
    min-height: 8rem;

    img, video {
        width: 100%;
        max-height: 30vh;
        object-fit: contain;
        display: block;
    }
}

.map {
    width: 100%;
    padding-bottom: 70%;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    margin-bottom: .5rem;
}

.skipped {
    color: gray;
}

@import "mixins";

.answer {
    @include conversationBox;
    width: 100%;

    input {
        flex-grow: 1;
        margin: 0 .2rem;
        padding: .4rem;
        resize: none;
        font-size: 1rem;
        font: inherit;
        background: transparent;
        border: none;
    }

    &:last-of-type {
        border-bottom-right-radius: 0;
    }

    &.button {
        background-color: $button-background;
        color: $text-negative;
        width: fit-content;
    }

    &.hidden {
        display: none;
    }
    
    &.selected {
        background-color: #fff;
        color: gray;
    }
}

.submit {
    @include conversationBox;
    background-color: $button-background;
    color: $text-negative;
    margin-left: auto;

    &:disabled {
        background-color: gray;
        border: gray;
        color: #aaa;
    }

    &:last-of-type {
        border-bottom-right-radius: 0;
    }
}
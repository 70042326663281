@import "styles/fonts/Akkurat/stylesheet.css";
@import url('https://fonts.googleapis.com/css2?family=Neuton&display=swap');

html,body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'AkkuratLLWeb-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16x;
  overflow-y: auto;
  background-color: rgb(246, 243, 243);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.material-icons {
  font-size: 1.5rem;
}

.pac-container {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  border-radius: 1rem;
}

.pac-item {
  background-color: rgba(255, 255, 255);
  padding: 1rem;
  font: inherit;
  margin-bottom: 1px;
}

.pac-item-query {
  font: inherit;
  font-size: inherit;
}

.pac-icon {
  margin-top: 0;
}

.pac-logo:after {
  margin-right: 1rem;
}

section, div, p, span, button, input, menu, nav {
  box-sizing: border-box;
}
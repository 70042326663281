.preview {
    width: 100%;
    padding-bottom: 75%;
    position: relative;
    overflow: hidden;

    .mediaContainer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    
    img, video {
        height: 100%;
        width: 100%;
    }
}

.cameraToggle {
    transition: background 250ms ease-in-out, 
    transform 250ms ease;

    &:active {
        background-color: unset;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
    }
}
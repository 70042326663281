@import "mixins";

.input {
    @include inputBox;
}

.inputPart {
    background-color: #fff;
    border-bottom: 1px solid #89ACCC;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    p {
        display: inline-block;
        margin: 0;
        padding: 1rem;
        min-height: 3rem;
        width: calc(100% - 3rem);
        word-break: break-word;
    }

    > .edit {
        display: inline-block;
        width: 2rem;
    }
}

// preview
.previewContainer {
    position: relative;
    width: 100%;
    
    .deleteMedia {
        position: absolute;
        top: 1rem;
        left: 1rem;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 1rem;
        padding: .2rem;
    }
}

.preview {
    width: 100%;
    min-height: 10rem;
    
    img, video {
        width: 100%;
        max-height: 30vh;
        object-fit: contain;
    }
}

.audioPreview {
    > button {
        margin: 0 auto;

        > span {
            font-size: 2.5rem;
        }
    }
}

//input bar
.inputBar {
    @include inputRow;
}

.upload {
    display: flex;
    text-align: center;
    padding: 0;
    
    span {
        font-size: 1.45rem;
    }

    >input {
        display: none;
    }
}

.camera {
    margin: 0 .3rem;
}




.audioPlayer {
    background-color: #fff;

    > button {
        margin: 0 auto;
        padding: .4rem 0;

        > span {
            font-size: 2.5rem;
        }
    }
}




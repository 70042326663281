.mic {
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;

    span {
        font-size: 2.5rem;
    }
}
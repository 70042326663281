@import "mixins";

.container {
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
}

.map {
    @include fixed-aspect;
}

.searchBar {
    position: relative;
    width: 100%;
}

.searchBox {
    @include input;
    background-color: $input-background;
    padding: 1rem 2.5rem;
    width: 100%;
    margin-top: 1px;
    border-radius: 0 0 1rem 1rem;
}

.searchIcon {
    left: .5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.returnIcon {
    position: absolute;
    right: .5rem;
    top: 50%;
    transform: translateY(-50%);
}

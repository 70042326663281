@import "mixins";

.userPage {
    background-color: #0d1a32;
    color: #fff;
    min-height: 100%;
}

.top {
    @include pageTop;
    padding-bottom: 1rem;
    background-color: unset;
    color: #fff
}

.pageMenu {
    @include section;
    padding: .5rem;
    list-style-type: none;

    li {
        display: inline-block;
        padding: 0 .5rem;

        &:not(:last-of-type){
            border-right: 1px solid #fff;
        }

        a {
            color: #fff
        }

        .active {
            text-decoration: none;
        }
    }
}

.form {
    max-width: 800px;

    .textLabel { 
        display: block;
        position: relative;
        width: 100%;
        margin-top: 3rem;

        span {
            position: absolute;
            left: .5rem;
            transform: translateY(-50%);
            padding: .5rem;
            background-color: #0d1a32
        }

        input {
            @include input;
            border: 1px solid #fff;
            padding: 1rem;
            color: #fff;
            width: 100%;

            &:disabled {
                border-color: #777;
                color: #777;
            }
        }
    }

    .checkbox {
        display: inline-flex;
        cursor: pointer;
        position: relative;
        margin-top: 2rem;
        align-items: center;
    }

    .checkbox > span {
        padding: 0 .3rem;
        user-select: none;
    }

    .checkbox > input {
        @include input;
        height: 1.5rem;
        width: 1.5rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        border: 1px solid #fff;
        outline: none;
        transition-duration: 0.3s;
        cursor: pointer;
      }
    
    .checkbox > input:checked + span::before {
        content: '\2713';
        display: block;
        text-align: center;
        color: #fff;
        position: absolute;
        left: 0.6rem;
        top: 0.3rem;
    }
}

.submitButton {
    @include submitButton;
    background: transparent;
    margin: 2rem auto;
    border-radius: unset;
}

@import "mixins";

.top {
    @include pageTop;
    overflow: hidden;
    padding-bottom: 1rem;

    .topicHeader {
        display: flex;
        flex-wrap: wrap-reverse;
        font-size: 1.25rem;
        margin: 0rem -2rem 2rem;
        max-width: 1540px;
  
        h1 {
            font-family: 'Neuton', serif;
            display: inline-block;
            margin: 3rem 0 0;
            font-size: 3.5rem;
        }

        > div {
            flex: 1;
            padding: 0 2rem;
            min-width: 350px;
       
            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.replyButton {
    border: 1px solid #000;
    padding: .7rem 1rem;
    margin-top: 3rem;
}

.overflowContainer {
    overflow: hidden;
}

.loader {
    @include loader;
    margin: 2rem auto;
    border: 0.4rem solid #fff;
    border-top: .4rem solid #3498db;
}

.topicResponses {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    column-rule: 1px solid #000;
    font-size: 1.125rem;
    margin: -1rem -2rem;
}

.response {
    break-inside: avoid;
    overflow: hidden;
    padding: 1rem 2rem;
    display: block;
    color: inherit;
    text-decoration: none;
}

.shortAnswer {
    font-size: 1.5rem;
    font-style: italic;
}

.longAnswer {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 20;
    -webkit-box-orient: vertical;
}

.mediaContainer {
    > label {
        display: inline-block;
        margin-top: .5rem;
        font-style: italic;

        &:empty {
            display: none;
        }
    } 
}

.author {
    display: inline-block;
    padding-top: .5rem;
    font-weight: 600;
}

.conversationWrapper {
    position: fixed;
    bottom: 0;
    right: 2rem;
    left: 2rem;
    transition: all .3s ease-in-out;

    &_menuVisible {
        transform: translateX(-36rem);
    }
}

.conversationContainer {
    margin: 0 0 0 auto;
    padding-bottom: .5rem;
    border-radius: 1rem 1rem 0 0;
    max-width: 600px;
    
    &_active {
        animation: slideUp .5s;
        background-color: #fff;
        box-shadow: 0 12px 28px rgba(0, 0, 0, .3), 0 2px 4px rgba(0, 0, 0, .5);

        .conversation{
            display: block;
        }

        .chatButton {
            box-shadow: none;
            margin: 0 0 0 auto;
        }
    }
}

.conversation {
    background-color: $theme-primary;
    padding: 1rem 1rem 0;
    overflow-y: scroll;
    max-height: 90vh;
    min-height: 40vh;
    overscroll-behavior: contain;
    display: none;
}

.chatButton {
    padding: 1rem;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 12px 28px rgba(0, 0, 0, .2);
    margin: 0 0 1rem auto;
}

.showMoreButton {
    margin: 0 auto;
    font-size: 1.25rem;

    span {
        font-size: 2rem;
    }
}

.aboutFooter {
    max-height: 0;
    transition: max-height .3s ease-out;
    overflow: hidden;
    height: auto;

    &_visible {
        max-height: 60rem;
        transition: max-height .3s ease-in;
    }
}

.aboutCard {
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-items: space-between;
    flex-wrap: wrap-reverse;

    .aboutBanner {
        flex: 1;
        min-width: 300px;
    }

    .aboutAuthor {
        flex: 1;
        display: flex;
        align-content: center;
        justify-content: center;
        margin: 0 auto;
        flex-wrap: wrap;
              
        .authorPresentation {
            padding: 2rem 4rem;

            .imageContainer {
                position: relative;
                padding-bottom: 100%;
                border-radius: 100%;
                overflow: hidden;
                min-width: 12rem;
    
                img {
                    position: absolute;
                    top: 0;
                    object-fit: cover;
                }
            }

            h3, h4 {
                text-align: center;
                margin: .4rem 0;
            }
        }

        .authorStatement {
            min-width: 200px;
            align-self: center;
            padding: 0 3rem;
            min-width: 300px;
        }
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(100%) translateY(-4rem);
    }
    100% {
        transform: translateY(0);
    }
}

@media screen and (max-width: 800px) {
    .topicResponses {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media screen and (max-width: 600px) {
    .conversationWrapper {
        right: 0;
        left: 0;
        
        &_menuVisible {
            right: 100%;
            left: -100%;
        }
    }

    .chatButton {
        margin: 0 1rem .5rem auto;
    }

    .topicResponses {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
}



.sequencer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 1rem 0 1rem 1rem;
    padding-left: 10%;
}

.animated > * {
    animation: flyIn .125s ease-out;
}

@keyframes flyIn {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    50% {
        opacity: .2;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@import "mixins";

.top {
    @include pageTop;
    padding-bottom: 1rem;
}

.conversation {
    max-width: 700px;
    margin: 0 auto;
}

@media screen and (max-width: 600px) {
    .conversationWrapper {
        right: 0;
        left: 0;
        
        &_menuVisible {
            right: 100%;
            left: -100%;
        }
    }
}



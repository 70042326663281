@import "mixins";

.dialogue {
    & + .dialogue {
        margin-top: 1rem;
    }
}

.question {
    @include question;

    & + .dialogue {
        margin-top: .5rem;
    }
}
@import "mixins";

.top {
    @include pageTop;
}

.frontPage {
    section {
        max-width: 50rem;
    }
}

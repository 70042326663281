$text-primary: #000;
$text-negative: #fff;
$border-color: #fff;
$focus-color: #35f335;

$theme-primary: #e0dacb;

$input-background: rgba(255,255,255, .75);
$button-background: #000;

$box-margin: .5rem
@import "mixins";

.button {
    border: none;
    background: transparent;
    color: inherit;
    font-size: inherit;
    font: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &[hidden] {
        display: none !important;
    } 
}

.icon {
    &.withLabel {
        margin-right: .2rem;
    }
}

.button > label, icon {
    pointer-events: none;
}

//submit button
.submitButton {
    @include conversationBox;
    @include submitButton;
    border-bottom-right-radius: 0;
    margin-left: auto;
}

.hasInput {
    flex-direction: row-reverse;

    .icon {
        margin-left: .2rem;
        margin-right: 0 !important;
    }
}



@import "mixins";

.statusMessage {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(177,177,177);
    text-align: center;

    .error {

    }
    
    .status {
    
    }

    .loader {
        @include loader;
    }
}

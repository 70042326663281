.dateRangeWrapper {
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 1rem;
    padding: 1rem;
}

.dateRange {
    align-items: center;
    display: flex;

    input {
        font: inherit;
        color: inherit;
        background-color: transparent;
        border: none;
        text-align: center;
        width: 6.5rem;


        &:invalid {
            border: 1px solid red;
        }

        &[type="date"]::-webkit-inner-spin-button {
            display: none;
        }
        &[type="date"]::-webkit-calendar-picker-indicator {
            display: none;
        }
    }

    span {
        margin: 0 .5rem;
    }
}

.errorRow {
    color: gray;
    display: flex;
    font-size: .75rem;
    padding-top: .2rem;
    padding-right: 2.5rem;


    > div:first-child {
        flex: 1 1 0;
        text-align: center;
    }

    >div {
        width: 1.5rem;
    }

    >div:last-child {
        flex: 1 1 0;
        text-align: center;
    }
}
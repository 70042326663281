.recordBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.record {
    span {
        color: red;
    }    
}
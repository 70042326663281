@import "mixins";

.inputBox {
    @include inputBox;
    margin-top: .5rem;
}

.inputRow {
    @include inputRow;

    >select {
        font-size: 1rem;
        padding: .2rem;
        width: 100%;
    }
}

.submit {
    @include conversationBox;
    background-color: $button-background;
    color: $text-negative;
    margin-left: auto;
}

.accepted {
    @include conversationBox;
    background-color: #fff;
    color: #000;
    margin-left: auto;
    border-bottom-right-radius: 0;
}
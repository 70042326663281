@import "mixins";

.memoryWizard {
    background-color:#89ACCC;
    min-height: 100vh;
}

.conversation {
    overflow-x: hidden;
}

.button {
    @include conversationBox;
    background-color: $button-background;
    color: $text-negative;
}
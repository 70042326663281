.flashing {
    position: relative;
    margin-left: 1.5rem;
    width: .5rem;
    height: .5rem;
    border-radius: .25rem;
    background-color: #000;
    opacity: .5;
    animation: dotFlashing .6s infinite linear alternate;
    animation-delay: .2s;
    transition: opacity .2s;

    &::before, &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        transition: opacity .2s;
    }

    &::before {
        left: -1rem;
        width: .5rem;
        height: .5rem;
        border-radius: 5px;
        background-color: #000;
        opacity: .5;
        animation: dotFlashing .6s infinite alternate;
        animation-delay: 0s;
      }
      
      &::after {
        left: 1rem;
        width: .5rem;
        height: .5rem;
        border-radius: 5px;
        background-color: #000;
        opacity: .5;
        animation: dotFlashing .6s infinite alternate;
        animation-delay: .6s;
      }
}
  
@keyframes dotFlashing {
    0% {
        opacity: .5;
    }
    50%,
    100% {
        opacity: 1;
    }
}
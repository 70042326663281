@import "mixins";

.questionPart {
    @include question;
}

.editRow {
    text-align: right;
    margin-bottom: 1rem;
    font-size: .75rem;

    button {
        display: inline-block;
    }
}

.skipped {
    color: gray;
}

.date {
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 1rem;
    display: flex;
    padding: 1rem;

    input {
        font: inherit;
        color: inherit;
        background-color: transparent;
        border: none;
    }

    [type="date"]::-webkit-inner-spin-button {
        display: none;
    }
    [type="date"]::-webkit-calendar-picker-indicator {
        display: none;
    }
}
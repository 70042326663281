@import "variables";

@mixin pageTop {
    padding-top: 7rem;
    background-color: $theme-primary;
}
@mixin question {
    border: 1px solid #000000;
    border-radius: 1rem;
    padding: 1rem;
    width: fit-content;
    max-width: calc(min(600px, 80%));
    line-height: 1.4;

    &.forDots {
        display: flex;
        width: 6rem;
        height: 2rem;
        align-items: center;
    }

    &:last-of-type {
        border-bottom-left-radius: 0;
    }

    &:not(:first-child) {
        margin-top: $box-margin;
    }
}

@mixin answer {
    margin: 1rem 0 0 auto;
}

@mixin conversationBox {
    @include input;
    border: 1px solid $border-color;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    background-color: rgba(255, 255, 255, 0.75);

    &:not(:first-child) {
        margin-top: $box-margin;
    } 
}

@mixin inputBox {
    background-color: rgba(255,255,255,.75);
    border-radius: 1rem;
    overflow: hidden;
    width: 100%;
}

@mixin inputRow {
    align-items: center;
    display: flex;
    padding: 1rem;

    > input {
        flex-grow: 1;
        margin: 0 .2rem;
        padding: .4rem;
        font-size: 1rem;
        font: inherit;
        background: transparent;
        border: none;
    }

    > textarea {
        flex-grow: 1;
        margin: 0 .2rem;
        padding: .4rem;
        resize: none;
        font-size: 1rem;
        font: inherit;
        background: transparent;
        border: none;
    }
}

@mixin input {
    font: inherit;
    font-size: inherit;
    line-height: 1.4;
    border: none;
    box-sizing: border-box;
    background-color: transparent;

    &:focus {
        outline: none;
        border: 2px solid $focus-color;
    }
}

@mixin fixed-aspect {
    position: relative;
    width: 100%;
    padding-bottom: 70%;
}

@mixin submitButton {
    @include input;
    border: 1px solid $border-color;
    border-radius: 1rem;
    padding: 1rem;
    background-color: $button-background;
    color: $text-negative;

    &:disabled {
        background-color: gray;
        border: gray;
        color: #aaa;
    }
}

@mixin section {
    max-width: 1400px;
    padding: 1rem;
    margin: 0 auto;
}

@mixin loader {
    border: .4rem solid #f3f3f3; 
    border-top: .4rem solid #3498db;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    animation: spin 2s linear infinite;
    margin-bottom: .5rem;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}